:root {
    /* PANTONE  1505 C */
    --orange-normal: rgb(255, 105, 0);

    /* PANTONE  Orange 021 C */
    --orange-acent: rgb(254, 80, 0);

    /* AZUL PANTONE  3025 C */
    --blue-normal: rgb(0, 79, 113);

    /* AZUL PANTONE  7546 C*/
    --blue-acent: rgb(37, 55, 70);

    --white-normal: #fff;
    --black-normal: #000;
    /*--main-bg-color: #DDE5ED;*/
    --main-bg-color: #E3E9F0;
    /*--main2-bg-color: #F2F5F8;*/
    --main2-bg-color: #EEF2F6;
    --main3-bg-color: var(--white-normal);

    /* HOME COLORS */
    --home-h2-color: var(--blue-normal);
    --hero-color: var(--blue-normal);

    /* ABOUTUS COLORS */
    --aboutus-main-bg-color : var(--main2-bg-color);
    --aboutus-h2-color: var(--orange-normal);
    --aboutus-h4-color: var(--blue-normal);
    --aboutus-font-color: var(--black-normal);
    /*--aboutus-h2-color: var(--orange-normal);*/
    /*--aboutus-h4-color: var(--white-normal);*/
    /*--aboutus-font-color: var(--white-normal);*/

    /* SERVICES COLORS */
    --services-main-bg-color: var(--main-bg-color);
    --services-h2-color: var(--orange-normal);
    --services-h4-color: var(--blue-normal);
    --services-font-color: var(--black-normal);
    --service-card-bg-color: var(--main2-bg-color);

    /* CLIENTS COLORS */
    --clients-main-bg-color: var(--main2-bg-color);
    --clients-h2-color: var(--orange-normal);
    --clients-h4-color: var(--blue-normal);
    --clients-font-color: var(--black-normal);
    --arrow-normal-color: var(--blue-normal);
    --arrow-hover-color: var(--blue-acent);

    /* CONTACT COLORS */
    --contact-main-bg-color: var(--main-bg-color);
    --contact-h2-color: var(--orange-normal);
    --contact-h4-color: var(--blue-normal);
    --contact-font-color: var(--black-normal);
    --contact-block-bg-color: var(--main2-bg-color);
}