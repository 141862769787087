/* ==========================================================================
   Navbar Style
   ========================================================================== */
.navbar-logo {
    /*filter: brightness(10);*/
    padding: 0 0 0 15px;
    margin: 0;
}

.logo-menu a {
    font-size: 20px;
    color: #fff;
}

.menu-bg {
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.menu-bg .menu-button {
    top: 0;
}

.menu-bg .menu-button:hover {
    cursor: pointer;
    color: #3c9cfd;
}

.menu-bg .logo-menu a {
    color: #3c9cfd;
}

.navbar {
    transition: all 0.2s ease;
    opacity: 1;
}

.navbar-transparent {
    background-color: var(--blue-normal);
}

.navbar-background {
    background-color: var(--blue-normal);
}

.dropdown-menu {
    background-color: var(--blue-normal);
}

.dropdown-item {
    color: #ffffff !important;
}

.dropdown-item:hover{
    background-color: transparent;
    /*text-decoration-color: #ffffff !important;
    text-decoration: underline;*/
}

#basic-nav-dropdown-item-es:before {
    content: '';
    position: absolute;
    bottom: 45px;
    left: 24px;
    width: 0;
    height: 2px;
    background: #ffffff;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
}

#basic-nav-dropdown-item-es:hover::before {
    width: 26%;
}

#basic-nav-dropdown-item-en:before {
    content: '';
    position: absolute;
    bottom: 13px;
    left: 24px;
    width: 0;
    height: 2px;
    background: #ffffff;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
}

#basic-nav-dropdown-item-en:hover::before {
    width: 26%;
}

.menu-bg .navbar-nav .nav-link {
    color: #ffffff !important;
}

.menu-bg .navbar-nav .nav-link:hover {
    color: #ffffff !important;
}

.menu-bg .navbar-nav .active {
    color: #ffffff !important;
}

.navbar-expand-md .navbar-nav .nav-link {
    color: #fff;
    font-size: 18px;
    padding: 5px 0 0 0;
    margin-left: 30px;
    font-family: 'Gotham', sans-serif;
    position: relative;
    line-height: 20px;
}

.language{
    height:100%;
    margin-top: -3px;
    margin-right: 4px;
}

@media (max-width: 678px) {
    .navbar-expand-md .navbar-nav .nav-link {
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 16px;
    }
}

.navbar-expand-md .navbar-nav .nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #ffffff;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    transition: 0.3s;
}

.navbar-expand-md .navbar-nav .active::before,
.navbar-expand-md .navbar-nav .nav-link:hover::before {
    width: 100%;
}

.navbar-brand {
    color: #fff;
}

.navbar-expand-md .navbar-nav .nav-link:focus,
.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link:hover:before {
    color: #ffffff;
}

.navbar-expand-md .navbar-nav .active > .nav-link,
.navbar-expand-md .navbar-nav .nav-link.active,
.navbar-expand-md .navbar-nav .nav-link.active:before,
.navbar-expand-md .navbar-nav .nav-link.open,
.navbar-expand-md .navbar-nav .open > .nav-link {
    color: #ffffff;
}

.navbar-expand-md .navbar-toggler {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    float: right;
}

.navbar-light .navbar-toggler-icon {
    /*filter: brightness(10);*/
    filter: grayscale(10%);
}

/*.navbar-toggler-icon {*/
/*    display: inline-block;*/
/*    width: 1em;*/
/*    height: 1em;*/
/*    vertical-align: middle;*/
/*    content: "";*/
/*    background: no-repeat center center;*/
/*    background-size: 100% 100%;*/
/*}*/

.navbar-expand-md .navbar-toggler-icon i {
    color: #fff !important;
}

.navbar-expand-md .navbar-toggler-icon:focus {
    outline: none;
}

.lang-menu {
    background-color: #fff;
}

.lang-item:hover {
    background-color: #ddd;
}

.full-width{
    width: 100%;
}

.flex-end{
    justify-content: flex-end;
}