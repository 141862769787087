/* ==========================================================================
   GENERAL
   ========================================================================== */
body {
    font-family: 'Gotham Book', sans-serif;
    color: #1F2041;
    font-size: 18px;
    background: #fff;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}

p {
    font-size: 18px;
    font-family: 'Gotham Book', sans-serif;
    margin-bottom: 0;
}

a:hover, a:focus {
    color: var(--blue-normal);
}

a {
    color: var(--blue-normal);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

h1, h2 {
    font-family: 'Gotham Ultra', sans-serif;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: .120em;
    color: var(--orange-acent);
}

h3, h4 {
    font-family: 'Gotham Bold', sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 200;
    letter-spacing: .200em;
    text-align: justify;
    color: var(--blue-normal);
}

h5, h6 {
    font-family: 'Gotham Book', sans-serif;
    font-size: 18px;
    font-weight: 120;
    text-align: justify;
}

@media (min-width: 1600px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1480px;
    }
}

@media (min-width: 1700px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1580px;
    }
}

@media (max-width: 992px) {
    .hidden-small {
        display: none;
    }
}

@media (max-width: 678px) {
    .hidden-mobile {
        display: none;
    }
}

@media (min-width: 678px) {
    .show-mobile {
        display: none;
    }
}

@media (max-width: 678px) {
    h1, h2 {
        font-size: 24px;
        font-weight: 200;
        text-align: left;
    }

    h3, h4 {
        font-size: 18px;
        font-weight: 120;
        text-align: left;
    }

    h5, h6 {
        font-size: 16px;
        font-weight: 50;
        text-align: left;
    }

    p {
        font-size: 16px;
        /*line-height: 20px;*/
        text-align: left;
    }
}

.amas {
    color: white;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

a:not([href]):not([tabindex]) {
    color: #fff;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: #fff;
}