@font-face {
    font-family: 'Gotham Ultra';
    font-style: normal;
    font-weight: 900;
    src: local('Gotham Ultra'), url('assets/fonts/Gotham-Ultra.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Bold'), url('assets/fonts/Gotham-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Book'), url('assets/fonts/Gotham-Book.woff') format('woff');
    font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Gotham';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: local('Gotham'), url('assets/fonts/GothamBook.woff') format('woff');*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Gotham Light';*/
/*    font-style: normal;*/
/*    font-weight: 300;*/
/*    src: local('Gotham Light'), url('assets/fonts/GothamLight.woff') format('woff');*/
/*    font-display: swap;*/
/*}*/