.aboutus {
    position:relative;
    display:block;
    background: var(--aboutus-main-bg-color);
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.aboutus h1, .aboutus h2 {
    color: var(--aboutus-h2-color);
}

.aboutus h3, .aboutus h4 {
    font-size: 23px;
    color: var(--aboutus-h4-color);
}

.aboutus h5, .aboutus h6 {
    color: var(--aboutus-font-color);
}

.aboutus p {
    color: var(--aboutus-font-color);
}

.aboutus .contents {
    display: flex;
    justify-content: flex-end;
    vertical-align: center;
    min-height: 50vh;
}

.contents-text {
    display: block;
    align-self: center;
    text-align: center;
    max-width: 680px;
}

.contents-text p {
    padding-top: .3rem;
    padding-bottom: .3rem;
    text-align: justify;
}

.aboutus .intro-img {
    display: flex;
    justify-content: flex-start;
    vertical-align: center;
    min-height: 50vh;
}

.aboutus .intro-img img {
    max-width: 500px;
}