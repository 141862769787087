.home {
    /*background: var(--main-bg-color);*/
}

.home h1, .home h2 {
    font-family: "Gotham Bold",sans-serif;
    font-size: 26px;
    font-weight: 200;
    letter-spacing: .2em;
    font-family: "Gotham Bold",sans-serif;
    color: var(--home-h2-color);
    line-height: 30px;
    margin-bottom: 25px;
    text-align: left;
}

.home h4, .home h5 {
    color: var(--hero-color);
    line-height: 30px;
    margin-bottom: 25px;
    text-align: left;
}

/* ==========================================================================
 Hero Production version
 ========================================================================== */
.hero-area-2 {
    /*color: #fff;*/
    overflow: hidden;
    position: relative;
}

.hero-area-2 .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: -moz-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    background-image: -webkit-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
    background-image: -ms-linear-gradient(0deg, #3c96ff 0%, #2dfbff 100%);
}

.hero-area-2 .contents {
    padding: 70px 0 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100vh;
}

.hero-area-2 .contents .btn {
    margin-top: 40px;
    margin-right: 20px;
    text-transform: uppercase;
}

.hero-area-2 .contents .btn-border {
    border: 2px solid var(--hero-color);
    color: var(--hero-color);
}

.hero-area-2 .contents .btn-border:hover {
    color: #fff;
    background-color: var(--hero-color);
    border-color: var(--hero-color);
}

@media (min-width: 992px) {
    .hero-area-2 .intro-img {
        padding: 70px 0 20px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 100vh;
    }
}

.hero-area-2 .intro-img img {
    display: block;
    height: auto;
    max-width: 100%;
}

.hero-area-2 .logo-img {
    padding: 40px 0 20px;
    margin: -3px 0 0;
}

.hero-area-2 .logo-img img {
    display: block;
    height: auto;
    max-width: 75%;
}

@media (max-width: 992px) {
    .hero-area-2 .logo-img img {
        max-width: 90%;
    }
}