.contact {
    position:relative;
    display:block;
    background: var(--contact-main-bg-color);
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.contact h1, .contact h2 {
    color: var(--contact-h2-color);
}

.contact h3, .contact h4 {
    color: var(--contact-h4-color);
}

.contact h5, .contact h6 {
    color: var(--contact-font-color);
}

.contact p {
    color: var(--contact-font-color);
}

.contact .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100vh;
}

.contact .contact-block {
    background: var(--contact-block-bg-color);
    overflow: hidden;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 43px 0 rgba(131, 131, 131, 0.23);
}

.offset-top {
    margin-top: -100px;
}