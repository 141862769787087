/* ==========================================================================
   Buttons
   ========================================================================== */
.btn {
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 30px;
    font-family: 'Gotham Bold', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
}

.btn-common {
    background-color: #3498db;
    position: relative;
    z-index: 1;
}

.btn-common:hover {
    color: #fff;
    box-shadow: 0 8px 9px 0 rgba(96, 94, 94, 0.17);
}

.btn-effect {
    overflow: hidden;
}

.btn-effect:after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-effect:hover:after {
    width: 100%;
}

.btn-primary {
    color: #fff;
    background-color: #263746;
    border-color: #263746;
}

.btn-primary:hover {
    background-color: #263746;
    border-color: #263746;
}

.btn-border {
    background-color: transparent;
    border: 2px solid var(--blue-normal);
    color: var(--blue-normal);
}

.btn-border:hover {
    color: #fff;
    background-color: var(--blue-normal);
    border-color: var(--blue-normal);
}

.btn-border-filled {
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #fff;
    color: #3c9cfd;
}

.btn-border-filled:hover {
    color: #3c9cfd;
    background-color: #ffffff;
    box-shadow: 0 8px 9px 0 rgba(96, 94, 94, 0.17);
}

.btn-subtitle {
    color: #191c1e;
    background: #dbecff;
    text-align: center;
    border-radius: 30px;
    padding: 6px 30px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 14px;
}

/* ==========================================================================
 tsparticles
 ========================================================================== */
#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: var(--main-bg-color);
    /*background-image: url("https://static.wixstatic.com/media/ea71bb_370144e721bc43c289bfb35fed3376d1~mv2_d_4500_2124_s_2.jpg/v1/fill/w_2890,h_1068,al_b,q_90,usm_0.66_1.00_0.01/ea71bb_370144e721bc43c289bfb35fed3376d1~mv2_d_4500_2124_s_2.webp");*/
}

/* ==========================================================================
 scrollTop
 ========================================================================== */
.scrollTop {
    width: 40px;
    line-height: 40px;
    overflow: hidden;
    z-index: 999;
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    transform: translateY(30px);
    right: 50px;
    color: var(--orange-normal);
    text-align: center;
    font-size: 30px;
    text-decoration: none;
}

.scrollTop:hover{
    color: var(--orange-acent);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}